<script lang="ts" setup>
import { useMounted } from "@vueuse/core";

interface Props {
  modelValue: string | null | undefined;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  inputClass?: string | object | Array<string | object>;
  type?: "text" | "password" | "email" | "number" | "textarea";
  field?: string;
  error?: string | null;
}

interface Emits {
  (e: "update:modelValue", value: string | null): void;
  (e: "change", value: string | null): void;
  (e: "focusout", value: string | null): void;
  (e: "submit"): void;
}

const props = withDefaults(defineProps<Props>(), {
  label: "",
  placeholder: "",
  disabled: false,
  inputClass: "",
  type: "text",
  field: "",
  error: "",
});

const emit = defineEmits<Emits>();
const isMounted = useMounted();

function submitOnEnter(event: KeyboardEvent) {
  if (event.key === "Enter") {
    emit("submit");
  }
}

const htmlFieldKind = props.type === "textarea" ? "textarea" : "input";
</script>

<template>
  <div>
    <label v-if="label" :for="field" class="text-sm">{{ label }}</label>
    <div class="relative">
      <slot name="prepend" />
      <component
        :is="htmlFieldKind"
        :id="field"
        :value="modelValue"
        :type="type !== 'textarea' ? type : undefined"
        class="w-full rounded-md border-2 p-4 focus:border-delight-blue-300 focus:ring-1 focus:ring-delight-blue-300"
        :class="[
          inputClass,
          {
            'border-yz-red-danger focus:border-yz-red-danger focus:ring-yz-red-danger': error,
            'animate-pulse bg-delight-neutral-100': !isMounted,
            'border-delight-neutral-200': !error,
            'bg-delight-neutral-100': disabled,
          },
        ]"
        :placeholder="placeholder"
        :disabled="!isMounted || disabled"
        :rows="type === 'textarea' ? 6 : undefined"
        @input="emit('update:modelValue', ($event.target as HTMLInputElement)?.value)"
        @change="emit('change', ($event.target as HTMLInputElement)?.value)"
        @focusout="emit('focusout', ($event.target as HTMLInputElement)?.value)"
        @keydown="submitOnEnter"
      />
      <div v-if="error" class="mt-1 text-sm text-yz-red-danger" v-html="error" />
      <slot name="append" />
    </div>
  </div>
</template>
